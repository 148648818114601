import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import styled from '@emotion/styled';
import {COLORS} from '../../../constants/colors.constant.ts';
import {
    formatDistance,
    formatNumber,
} from '../../../utils/translations.utils.ts';
import {
    extractNumberFromFormattedDistance,
    extractUnitFromFormattedDistance,
} from '../../../utils/utils.utils.ts';
import {Odometer, OdometerSocketEventData} from '../../../types/odometer';
import axios from 'axios';
import {API_URL, SOCKET_URL} from '../../../constants/global.constant.ts';
import {useParams} from 'react-router-dom';
import {io} from 'socket.io-client';
import georideLogoMonochromeBlack from '../../../assets/images/GeoRide-logo-monochrome-black.png';
import {PoweredBy} from '../../../components/PoweredBy.tsx';
import {BREAKPOINTS} from '../../../constants/styles.constant.ts';
import {
    getDiffInMinutesBetweenISODates,
    getNow,
} from '../../../utils/date.utils.ts';
import {useWindowFocus} from '../../../hooks/useWindowFocus.tsx';

export interface ProgressViewProps {}

const MAX_ODOMETER_VALUE = 60000000; // in meters
const PROGRESS_BAR_DIVIDER_COUNT = 5;

export const ProgressView: React.FC<ProgressViewProps> = ({}) => {
    const lastOdometerRefreshDate = useRef(getNow());

    const {trackerId} = useParams();

    const [isLoading, setIsLoading] = useState(false);
    const [odometer, setOdometer] = useState<number>(0);
    const [socket, setSocket] = useState<null | Odometer['socket']>(null);

    const getOdometer = useCallback(async () => {
        if (isLoading) {
            return;
        }

        setIsLoading(true);

        try {
            const res = await axios.get(
                `${API_URL}/tracker/${trackerId}/widget/ducati/odometer`,
            );

            const data: Odometer = res.data;

            setOdometer(data.odometer);
            setSocket(data.socket);
            lastOdometerRefreshDate.current = getNow();
        } catch (e) {
            /* empty */
        } finally {
            setIsLoading(false);
        }
    }, [isLoading, trackerId]);

    const odometerInKilometers = Math.round(odometer / 1000);

    const progressBarValue = useMemo(() => {
        const progressValue = (odometer / MAX_ODOMETER_VALUE) * 100;

        if (progressValue > 100) {
            return 100;
        }

        return progressValue;
    }, [odometer]);

    const currentOdometerValueFormatted = formatDistance(odometerInKilometers);

    useEffect(() => {
        getOdometer();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOnWindowFocus = useCallback(() => {
        if (
            getDiffInMinutesBetweenISODates(
                getNow(),
                lastOdometerRefreshDate.current,
            ) > 5
        ) {
            getOdometer();
        }
    }, [getOdometer]);

    useWindowFocus(handleOnWindowFocus);

    useEffect(() => {
        if (!socket) {
            return;
        }

        const socketClient = io(SOCKET_URL, {
            reconnection: true,
            transports: ['websocket'],
            auth: {
                token: socket.token,
                ...socket.headers,
            },
        });

        socketClient.on('odometer', (odometer: OdometerSocketEventData) => {
            setOdometer((value) => value + odometer.add);
        });

        return () => {
            socketClient.off('odometer');
            socketClient.disconnect();
        };
    }, [getOdometer, socket]);

    return (
        <Wrapper>
            <Container>
                <OdometerContainer>
                    <OdometerCounter>
                        {formatNumber(
                            extractNumberFromFormattedDistance(
                                currentOdometerValueFormatted,
                            ),
                        )}

                        <span>
                            {extractUnitFromFormattedDistance(
                                currentOdometerValueFormatted,
                            )}
                        </span>
                    </OdometerCounter>
                </OdometerContainer>

                <ProgressBarContainer>
                    {Array.from({length: PROGRESS_BAR_DIVIDER_COUNT}).map(
                        (_, index) => {
                            const offset =
                                ((index + 1) /
                                    (PROGRESS_BAR_DIVIDER_COUNT + 1)) *
                                100;

                            return (
                                <ProgressBarDivider
                                    key={index}
                                    isLight={offset < progressBarValue}
                                    offset={offset}
                                />
                            );
                        },
                    )}

                    <ProgressBar progress={progressBarValue} />
                </ProgressBarContainer>

                <ProgressBarInfos>
                    <ProgressText>{formatDistance(0)}</ProgressText>

                    <PoweredBy logoSrc={georideLogoMonochromeBlack} />

                    <ProgressText>
                        {formatDistance(MAX_ODOMETER_VALUE / 1000)}
                    </ProgressText>
                </ProgressBarInfos>
            </Container>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    pointer-events: none;
`;

const Container = styled.div`
    max-width: 625px;
    width: 100%;
    background-color: ${() => COLORS.OFF_WHITE + 'D9'};
    padding: 20px 40px 35px 40px;
    margin: 0 40px;
    border-radius: 8px;
    pointer-events: auto;

    @media screen and (max-width: ${BREAKPOINTS.TABLET}) {
        max-width: 560px;
        margin: 0 20px;
    }
`;

const OdometerContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 4px;
    margin: 20px;
`;

const OdometerCounter = styled.p`
    @media screen and (max-width: ${BREAKPOINTS.TABLET}) {
        font-size: 6.4em;
    }

    @media screen and (max-width: ${BREAKPOINTS.MOBILE}) {
        font-size: 4em;
    }

    font-family: Ducati-Style-Extra-Bold, sans-serif;
    font-size: 7.2em;
    line-height: 0.7;

    & > span {
        font-family: Ducati-Style-Bold, sans-serif;
        font-size: 0.4em;
        line-height: 1;
        margin-left: 0.25em;
    }
`;

const ProgressBarContainer = styled.div`
    height: 20px;
    border-radius: 4px;
    border-color: ${() => COLORS.SMOKY_BLACK};
    border-style: solid;
    border-width: 2px;
    padding: 2px;
    position: relative;
    margin-bottom: 5px;

    @media screen and (max-width: ${BREAKPOINTS.TABLET}) {
        height: 18px;
    }
`;

const ProgressBarDivider = styled.div<{isLight: boolean; offset: number}>`
    background-color: ${({isLight}) =>
        isLight ? COLORS.OFF_WHITE : COLORS.SMOKY_BLACK};
    height: calc(100% - 4px);
    width: 2px;
    position: absolute;
    top: 2px;
    left: ${({offset}) => offset + '%'};
    z-index: 1;
`;

const ProgressBar = styled.div<{progress: number}>`
    background-color: ${() => COLORS.ROSSO_DUCATI};
    height: 100%;
    width: ${({progress}) => progress + '%'};
    border-radius: 2px;
`;

const ProgressBarInfos = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const ProgressText = styled.p`
    font-family: Ducati-Style-Bold, sans-serif;
    font-size: 1em;
    margin-top: 1px;

    @media screen and (max-width: ${BREAKPOINTS.TABLET}) {
        font-size: 0.9em;
    }

    @media screen and (max-width: ${BREAKPOINTS.MOBILE}) {
        font-size: 0.7em;
    }
`;
