import {getRandomKey} from '../../utils/utils.utils';
import mapboxgl from 'mapbox-gl';
import {Position as GRPosition} from '../../types/tracker';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import {lineString, Position as TurfPosition} from '@turf/helpers';
import {COLORS} from '../../constants/colors.constant.ts';
import {MAP_ANIMATION_DURATION} from '../../constants/global.constant.ts';

export default class LineString {
    private id: string = getRandomKey('line');
    private map: mapboxgl.Map | null = null;
    private positions: TurfPosition[] = [];
    private color: string = COLORS.DARK_GREY;
    private animationFrame = 0;
    private withShadow = false;

    constructor(
        map: mapboxgl.Map,
        positions: GRPosition[],
        id: string,
        color?: string,
        withShadow?: boolean,
    ) {
        this.map = map;

        this.id = getRandomKey(`line-${id}`);

        this.positions = positions.map((position) => [
            position.longitude,
            position.latitude,
        ]);

        if (color) {
            this.color = color;
        }

        if (typeof withShadow === 'boolean') {
            this.withShadow = withShadow;
        }
    }

    getSource(): mapboxgl.AnySourceData {
        return {
            type: 'geojson',
            data: lineString(this.positions),
        };
    }

    getLayer(): mapboxgl.AnyLayer {
        return {
            id: this.id,
            type: 'line',
            source: this.id,
            layout: {
                'line-join': 'round',
                'line-cap': 'round',
            },
            paint: {
                'line-color': this.color,
                'line-width': 6,
            },
        };
    }

    getShadowLayer(): mapboxgl.AnyLayer {
        return {
            id: `${this.id}-shadow`,
            type: 'line',
            source: this.id,
            layout: {
                'line-join': 'round',
                'line-cap': 'round',
            },
            paint: {
                'line-color': COLORS.BLACK,
                'line-width': 12,
                'line-opacity': 0.3,
                'line-blur': 10,
            },
        };
    }

    addToMap() {
        if (!this.map) {
            return;
        }

        this.map.addSource(this.id, this.getSource());

        if (this.withShadow) {
            this.map.addLayer(this.getShadowLayer());
        }

        this.map.addLayer(this.getLayer());
    }

    removeFromMap() {
        if (!this.map) {
            return;
        }

        try {
            if (this.map.getLayer(this.id)) {
                const shadowLayerId = `${this.id}-shadow`;

                if (this.withShadow && this.map.getLayer(shadowLayerId)) {
                    this.map.removeLayer(shadowLayerId);
                }

                this.map.removeLayer(this.id);
                this.map.removeSource(this.id);
            }
        } catch (e) {
            /* empty */
        }
    }

    updateGeoJson(position: GRPosition) {
        if (
            !this.map ||
            typeof position.longitude !== 'number' ||
            typeof position.latitude !== 'number'
        ) {
            return;
        }

        const source = this.map.getSource(this.id);

        if (source && typeof source === 'object') {
            if (source.type === 'geojson') {
                source.setData(
                    lineString([
                        ...this.positions,
                        [position.longitude, position.latitude],
                    ]),
                );
            }
        }
    }

    updatePosition(position: GRPosition) {
        this.positions = [
            ...this.positions,
            [position.longitude, position.latitude],
        ];
    }

    addPosition(position: GRPosition) {
        this.updateGeoJson(position);
        this.updatePosition(position);
    }

    animateTo(position: GRPosition, options?: {duration?: number}) {
        window.cancelAnimationFrame(this.animationFrame);

        const currentCoords = this.positions[this.positions.length - 1];

        const currentPosition = {
            longitude: currentCoords[0],
            latitude: currentCoords[1],
        };

        if (!currentPosition) {
            return null;
        }

        const diffLng = position.longitude - currentPosition.longitude;
        const diffLat = position.latitude - currentPosition.latitude;

        const duration = options?.duration || MAP_ANIMATION_DURATION;
        const fps = 30;
        const interval = 1000 / fps;
        let startTime: number | null = null;
        let then: number | null = null;

        const animate = (now: number) => {
            if (!then) {
                then = now;
            }

            if (!startTime) {
                startTime = now;
            }

            //Limit fps for better performances
            const delta = now - then;

            if (delta < interval) {
                return (this.animationFrame =
                    window.requestAnimationFrame(animate));
            }

            then = now - (delta % interval);

            const timeRemaining = startTime + duration - now; //ex: 250ms/300ms
            const completion = now - startTime; //ex: 50ms/300ms
            const percentageCompletion = (completion / duration) * 100; //ex: 16.5% of 300ms

            if (Math.floor(timeRemaining) <= 0) {
                this.updateGeoJson(position);
                this.updatePosition(position);
                window.cancelAnimationFrame(this.animationFrame);
                return;
            }

            const nextPositions = {
                longitude:
                    currentPosition.longitude +
                    (diffLng * percentageCompletion) / 100,
                latitude:
                    currentPosition.latitude +
                    (diffLat * percentageCompletion) / 100,
            };

            this.updateGeoJson(nextPositions as GRPosition);

            this.animationFrame = window.requestAnimationFrame(animate);
        };

        this.animationFrame = window.requestAnimationFrame(animate);
    }
}
