import React, {
    useCallback,
    useEffect,
    useImperativeHandle,
    useState,
} from 'react';
import LineStringHelper from '../../class/mapbox/LineString';
import {Position} from '../../types/tracker';
import {useMap} from './Map';
import {getMapFitBounds} from '../../utils/map.utils.ts';
import {DEFAULT_MAP_FIT_BOUNDS_OPTIONS} from '../../constants/global.constant.ts';

export interface LineStringProps {
    color: string;
    positions: Position[];
    focus?: boolean;
    animated?: boolean;
    withShadow?: boolean;
    id: string;
}

export interface LineStringRef {
    removeLineString: () => void;
}

// eslint-disable-next-line react/display-name
const LineString = React.forwardRef<LineStringRef, LineStringProps>(
    ({color, positions, id, focus, animated, withShadow}, ref) => {
        const [lineString, setLineString] = useState<LineStringHelper | null>(
            null,
        );

        const {map, isMapRendered, isStyleLoaded} = useMap();

        const createLineString = useCallback(() => {
            if (!lineString && map && positions.length > 1) {
                const lineStringHelper = new LineStringHelper(
                    map,
                    positions,
                    id,
                    color,
                    withShadow,
                );

                lineStringHelper.addToMap();

                setLineString(lineStringHelper);
            }
        }, [color, lineString, map, positions, id, withShadow]);

        useEffect(() => {
            if (isMapRendered && isStyleLoaded) {
                createLineString();
            }
        }, [createLineString, isMapRendered, isStyleLoaded]);

        useEffect(() => {
            try {
                if (lineString && focus) {
                    const mapFitBounds = getMapFitBounds(positions);

                    if (mapFitBounds) {
                        map?.fitBounds(
                            mapFitBounds,
                            DEFAULT_MAP_FIT_BOUNDS_OPTIONS,
                        );
                    }
                }
            } catch (e) {
                /* empty */
            }
        }, [lineString, focus, map, positions]);

        useEffect(() => {
            const newPosition = positions[positions.length - 1];

            if (lineString && animated) {
                lineString?.animateTo?.(newPosition);
            } else if (lineString) {
                lineString?.addPosition?.(newPosition);
            }
        }, [lineString, animated, positions]);

        const handleRemoveLineString = useCallback(() => {
            if (!lineString) {
                return;
            }

            lineString.removeFromMap();
            setLineString(null);
        }, [lineString]);

        useImperativeHandle(
            ref,
            () => ({
                removeLineString: handleRemoveLineString,
            }),
            [handleRemoveLineString],
        );

        return null;
    },
);

export default React.memo(LineString);
