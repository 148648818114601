import {useEffect} from 'react';

export interface UseWindowFocus {}

export const useWindowFocus = (callback: () => void): UseWindowFocus => {
    useEffect(() => {
        window.addEventListener('focus', callback);
        return () => window.removeEventListener('focus', callback);
    }, [callback]);

    return {};
};
