export const getCityAndCountryStringFromAddress = (address: string): string => {
    const splitAddress = address.split(',');

    const city = splitAddress?.[0];
    const country = splitAddress?.[splitAddress.length - 1];

    if (city && !country) {
        return city;
    }

    if (country && !city) {
        return country;
    }

    return `${city}, ${country}`;
};
