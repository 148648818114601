import React, {useCallback, useEffect, useRef} from 'react';
import MarkerClass from '../../class/mapbox/Marker';
import {MarkerProperties, MarkerType} from '../../types/marker';
import {Callback} from '../../types';
import {useMap} from './Map';
import {getLngLatLike} from '../../utils/map.utils.ts';
import {MAP_ANIMATION_DURATION} from '../../constants/global.constant.ts';

export interface MarkerProps {
    latitude: number | undefined;
    longitude: number | undefined;
    type: MarkerType;
    onClick?: Callback;
    canUpdate?: boolean;
    animated?: boolean;
    animationDuration?: number;
}

const Marker: React.FC<MarkerProps> = ({
    latitude,
    longitude,
    type,
    onClick,
    canUpdate = true,
    animated,
    animationDuration = MAP_ANIMATION_DURATION,
}) => {
    const marker = useRef<MarkerClass | null>(null);

    const {map, isMapRendered} = useMap();

    const createMarker = useCallback(
        (markerProperties: MarkerProperties) => {
            if (
                map &&
                !marker.current &&
                typeof latitude === 'number' &&
                typeof longitude === 'number'
            ) {
                const newMarker = new MarkerClass(markerProperties).setLngLat(
                    getLngLatLike(latitude, longitude),
                );

                newMarker.addTo(map);

                marker.current = newMarker;
            }
        },
        [latitude, longitude, map],
    );

    useEffect(() => {
        if (
            isMapRendered &&
            !marker.current &&
            typeof latitude === 'number' &&
            typeof longitude === 'number'
        ) {
            createMarker(MarkerClass.createDotMarker(type, onClick));
        }
    }, [createMarker, type, onClick, isMapRendered, latitude, longitude]);

    useEffect(() => {
        return () => {
            if (marker.current) {
                marker.current.remove();
                marker.current = null;
            }
        };
    }, [isMapRendered]);

    useEffect(() => {
        if (
            !canUpdate ||
            typeof latitude !== 'number' ||
            typeof longitude !== 'number' ||
            !marker.current
        ) {
            return;
        }

        if (animated) {
            marker.current.animateTo(latitude, longitude, animationDuration);
        } else {
            marker.current.setLngLat(getLngLatLike(latitude, longitude));
        }
    }, [latitude, longitude, marker, canUpdate, animationDuration, animated]);

    return null;
};

export default React.memo(Marker);
