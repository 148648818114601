import React from 'react';
import {useTranslation} from 'react-i18next';

export interface NotFoundViewProps {}

export const NotFoundView: React.FC<NotFoundViewProps> = ({}) => {
    const {t} = useTranslation();

    return (
        <div>
            <h1>{t('features.not-found.views.NotFoundView.title')}</h1>
            <p>{t('features.not-found.views.NotFoundView.message')}</p>
        </div>
    );
};
