import React from 'react';
import {Icon, IconName} from './Icon';
import styled from '@emotion/styled';
import {COLORS} from '../../../constants/colors.constant.ts';

export interface InfoItemProps {
    label: string;
    icon: IconName;
    isPremium?: boolean;
    iconSize?: number;
}

export const InfoItem: React.FC<InfoItemProps> = ({
    label,
    icon,
    isPremium,
    iconSize,
}) => {
    return (
        <Container>
            <Icon name={icon} size={iconSize ?? 22} />

            <Label>{label}</Label>

            {isPremium && (
                <PremiumContainer>
                    <PremiumLabel>Premium</PremiumLabel>
                </PremiumContainer>
            )}
        </Container>
    );
};

const Container = styled.div`
    background-color: ${() => COLORS.ORANGE + '26'};
    width: 100px;
    height: 110px;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;
    border-radius: 10px;
`;

const Label = styled.p`
    color: ${() => COLORS.ORANGE};
    font-weight: 600;
    line-height: 1;
    text-align: center;
    margin-top: 10px;
    font-size: 12px;
    font-family: 'Avenir-Regular', Helvetica, sans-serif;
`;

const PremiumContainer = styled.div`
    background-color: ${() => COLORS.ORANGE};
    padding: 1px 8px;
    border-radius: 13px;
    margin-top: 10px;
`;

const PremiumLabel = styled.p`
    color: ${() => COLORS.WHITE};
    font-size: 12px;
    font-family: 'Avenir-Regular', Helvetica, sans-serif;
`;
