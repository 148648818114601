import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import georideLogo from '../../../assets/images/GeoRide-logo.png';
import motorcycle from '../../../assets/images/multistrada.png';
import styled from '@emotion/styled';
import {COLORS} from '../../../constants/colors.constant.ts';
import {PoweredBy} from '../../../components/PoweredBy.tsx';
import {InfoItems} from '../components/InfoItems.tsx';
import {formatDistance} from '../../../utils/translations.utils.ts';
import {Map, MapRef} from '../../../components/mapbox/Map.tsx';
import Marker from '../../../components/mapbox/Marker.tsx';
import LineString, {
    LineStringRef,
} from '../../../components/mapbox/LineString.tsx';
import {
    LiveShareFirstPosition,
    LiveShareLastPosition,
    LiveShareLiveShare,
    LiveSharePosition,
    LiveShareStartDate,
} from '../../../types/live-share';
import axios from 'axios';
import {
    API_URL,
    MAP_ANIMATION_DURATION,
    SOCKET_URL,
} from '../../../constants/global.constant.ts';
import {useParams} from 'react-router-dom';
import {io} from 'socket.io-client';
import calcDistance from '../../../utils/distance.utils.ts';
import {
    formatDateToLocaleStringWithoutYear,
    getDiffInDaysBetweenISODates,
    getDiffInMinutesBetweenISODates,
    getNow,
} from '../../../utils/date.utils.ts';
import {DateTime} from 'luxon';
import {getCityAndCountryStringFromAddress} from '../../../utils/address.utils.ts';
import {BREAKPOINTS} from '../../../constants/styles.constant.ts';
import {firstLetterToUpperCase} from '../../../utils/text.utils.ts';
import {useWindowFocus} from '../../../hooks/useWindowFocus.tsx';

export interface MapViewProps {}

export const MapView: React.FC<MapViewProps> = ({}) => {
    const mapRef = React.useRef<null | MapRef>(null);
    const lineStringRef = React.useRef<null | LineStringRef>(null);

    const {t} = useTranslation();
    const {trackerId} = useParams();

    const [isLoading, setIsLoading] = useState(false);
    const [lastFetchDate, setLastFetchDate] = useState<string | null>(null);
    const [positions, setPositions] = useState<LiveSharePosition[]>([]);
    const [liveShare, setLiveShare] = useState<LiveShareLiveShare | null>(null);
    const [liveShareStartDate, setLiveShareStartDate] =
        useState<LiveShareStartDate | null>(null);
    const [liveShareFirstPosition, setLiveShareFirstPosition] =
        useState<LiveShareFirstPosition | null>(null);
    const [liveShareLastPosition, setLiveShareLastPosition] =
        useState<LiveShareLastPosition | null>(null);
    const [totalMileage, setTotalMileage] = useState(0);
    const [isCameraFocusTrip, setIsCameraFocusTrip] = useState(true);

    const getLiveShare = useCallback(async () => {
        if (isLoading) {
            return;
        }

        setIsLoading(true);

        try {
            const {data} = await axios.get(
                `${API_URL}/tracker/${trackerId}/widget/ducati/share`,
            );

            setLiveShare(data.liveShare);
            setLiveShareStartDate(data.startDate);
            setLiveShareFirstPosition(data.firstPosition);
            setLiveShareLastPosition(data.lastPosition);
            setLastFetchDate(getNow());
        } catch (e) {
            /* empty */
        } finally {
            setIsLoading(false);
        }
    }, [isLoading, trackerId]);

    const getLiveSharePositions = useCallback(async () => {
        if (!liveShare?.slug) {
            return;
        }

        setIsLoading(true);

        try {
            const {data} = await axios.get(
                `${API_URL}/live/${liveShare.slug}/positions`,
            );

            if (data?.length > 0) {
                const totalMileage = data.reduce(
                    (
                        acc: number,
                        position: LiveSharePosition,
                        index: number,
                    ) => {
                        if (index === 0) {
                            return acc;
                        }

                        const previousPosition = data[index - 1];

                        return (
                            acc +
                            calcDistance(
                                previousPosition.latitude,
                                previousPosition.longitude,
                                position.latitude,
                                position.longitude,
                            )
                        );
                    },
                    0,
                );

                setTotalMileage(totalMileage);
            }

            setPositions(data ?? []);
        } catch (e) {
            /* empty */
        } finally {
            setIsLoading(false);
        }
    }, [liveShare?.slug]);

    const handleOnWindowFocus = useCallback(() => {
        if (lineStringRef.current) {
            lineStringRef.current.removeLineString();
        }

        getLiveShare();
        getLiveSharePositions();
    }, [getLiveShare, getLiveSharePositions]);

    useWindowFocus(handleOnWindowFocus);

    useEffect(() => {
        getLiveShare();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (liveShare?.slug) {
            getLiveSharePositions();
        }
    }, [liveShare?.slug, getLiveSharePositions]);

    useEffect(() => {
        if (!liveShare?.slug) {
            return;
        }

        const socketClient = io(SOCKET_URL, {
            reconnection: true,
            transports: ['websocket'],
            auth: {
                token: liveShare?.slug,
                live: true,
            },
        });

        socketClient.on('position', (position: LiveSharePosition) => {
            setPositions((prevPositions) => {
                const lastPrevPosition =
                    prevPositions[prevPositions.length - 1];

                if (lastFetchDate) {
                    const diffInMinutes = getDiffInMinutesBetweenISODates(
                        getNow(),
                        lastFetchDate,
                    );

                    if (diffInMinutes > 1 || !prevPositions.length) {
                        getLiveShare();
                    }
                }

                setTotalMileage((prevTotalMileage) => {
                    if (lastPrevPosition) {
                        return (
                            prevTotalMileage +
                            calcDistance(
                                lastPrevPosition.latitude,
                                lastPrevPosition.longitude,
                                position.latitude,
                                position.longitude,
                            )
                        );
                    }

                    return prevTotalMileage;
                });

                return [...prevPositions, position];
            });
        });

        return () => {
            socketClient.off('position');

            if (socketClient.connected) {
                socketClient.disconnect();
            }
        };
    }, [liveShare?.slug, lastFetchDate, getLiveShare]);

    const lastPosition = useMemo(() => {
        return positions[positions.length - 1] ?? liveShareLastPosition;
    }, [liveShareLastPosition, positions]);

    const firstPosition = positions[0];

    useEffect(() => {
        if (lastPosition && positions.length <= 1 && mapRef.current?.map) {
            mapRef.current.map.flyTo({
                duration: MAP_ANIMATION_DURATION,
                zoom: 16.5,
                center: [lastPosition.longitude, lastPosition.latitude],
            });
        }
    }, [lastPosition, positions.length]);

    const daysCount = Math.ceil(
        getDiffInDaysBetweenISODates(getNow(), liveShareStartDate ?? getNow()),
    );

    return (
        <Wrapper>
            <Container>
                <PoweredByContainer>
                    <PoweredBy logoSrc={georideLogo} bold />
                </PoweredByContainer>

                <Map
                    ref={mapRef}
                    width={'100%'}
                    height={'100%'}
                    cooperativeGestures
                    onDragStart={() => setIsCameraFocusTrip(false)}
                >
                    {typeof firstPosition?.latitude === 'number' &&
                    typeof firstPosition?.longitude === 'number' ? (
                        <Marker
                            type={'start'}
                            latitude={firstPosition.latitude}
                            longitude={firstPosition.longitude}
                            canUpdate
                            animated
                        />
                    ) : null}

                    {typeof lastPosition?.latitude === 'number' &&
                    typeof lastPosition?.longitude === 'number' ? (
                        <Marker
                            type={'current'}
                            latitude={lastPosition.latitude}
                            longitude={lastPosition.longitude}
                            animated
                            canUpdate
                            onClick={() => setIsCameraFocusTrip(true)}
                        />
                    ) : null}

                    {positions.length > 1 ? (
                        <LineString
                            ref={lineStringRef}
                            color={COLORS.DARK_GREY}
                            id={'trip-line'}
                            positions={positions}
                            withShadow
                            animated
                            focus={isCameraFocusTrip}
                        />
                    ) : null}
                </Map>

                <InfosWrapper>
                    <InfosContainer>
                        <InfosMotorcycleImageContainer>
                            <InfosMotorcycleImage src={motorcycle} />
                        </InfosMotorcycleImageContainer>

                        <InfoColumns>
                            <InfoColumn>
                                <InfoItems
                                    label={t(
                                        'features.map.views.MapView.dayCount',
                                        {
                                            count: daysCount,
                                        },
                                    )}
                                    value={firstLetterToUpperCase(
                                        formatDateToLocaleStringWithoutYear(
                                            getNow(),
                                            DateTime.DATE_HUGE,
                                        ),
                                    )}
                                />

                                <InfoItems
                                    label={t(
                                        'features.map.views.MapView.todaysMileage',
                                    )}
                                    isOdometer
                                    value={formatDistance(
                                        Math.round(totalMileage / 1000),
                                    )}
                                />
                            </InfoColumn>

                            <InfoColumnDivider />

                            <InfoColumn>
                                <InfoItems
                                    label={t(
                                        'features.map.views.MapView.start',
                                    )}
                                    value={
                                        liveShareFirstPosition?.address
                                            ? getCityAndCountryStringFromAddress(
                                                  liveShareFirstPosition.address,
                                              )
                                            : t('common.unknown')
                                    }
                                    isLight
                                />

                                <InfoItems
                                    label={
                                        liveShareLastPosition?.moving
                                            ? t(
                                                  'features.map.views.MapView.running',
                                              )
                                            : t(
                                                  'features.map.views.MapView.atRest',
                                              )
                                    }
                                    value={
                                        liveShareLastPosition?.address
                                            ? getCityAndCountryStringFromAddress(
                                                  liveShareLastPosition.address,
                                              )
                                            : t('common.unknown')
                                    }
                                    showLabelStatus={
                                        liveShareLastPosition?.moving
                                    }
                                />
                            </InfoColumn>
                        </InfoColumns>
                    </InfosContainer>
                </InfosWrapper>
            </Container>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    width: 100vw;
    height: 100vh;
`;

const Container = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
`;

const PoweredByContainer = styled.div`
    position: absolute;
    top: 20px;
    left: 30px;
    z-index: 99;
`;

const InfosWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: end;
    justify-content: center;
    padding: 0 30px 25px 30px;
    position: absolute;
    top: 0;
    pointer-events: none;

    @media screen and (max-width: ${BREAKPOINTS.TABLET}) {
        padding: 0 20px 30px 20px;
    }

    @media screen and (max-width: ${BREAKPOINTS.MOBILE}) {
        padding: 0 15px 40px 15px;
    }
`;

const InfosContainer = styled.div`
    background-color: ${() => COLORS.OFF_WHITE};
    padding: 20px 160px;
    border-radius: 8px;
    box-shadow: 0px 0px 20px 10px ${() => COLORS.BLACK + '26'};
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: center;
    overflow: hidden;
    pointer-events: auto;

    @media screen and (max-width: ${BREAKPOINTS.DESKTOP}) {
        width: 100%;
        padding: 15px 0 15px 130px;
    }

    @media screen and (max-width: ${BREAKPOINTS.TABLET}) {
        padding: 15px;
        width: 100%;
    }
`;

const InfosMotorcycleImageContainer = styled.div`
    position: absolute;
    height: 70%;
    left: 0;

    @media screen and (max-width: ${BREAKPOINTS.TABLET}) {
        display: none;
    }
`;

const InfosMotorcycleImage = styled.img`
    height: 100%;
    margin-left: -50%;
`;

const InfoColumns = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 40px;

    @media screen and (max-width: ${BREAKPOINTS.DESKTOP}) {
        gap: 30px;
    }

    @media screen and (max-width: ${BREAKPOINTS.MOBILE}) {
        gap: 15px;
    }
`;

const InfoColumnDivider = styled.div`
    background-color: ${() => COLORS.FEATURE_GREY};
    width: 4px;
    border-radius: 2px;

    @media screen and (max-width: ${BREAKPOINTS.DESKTOP}) {
        width: 3px;
    }
`;

const InfoColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
`;
