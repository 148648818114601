import {Position} from '../types/tracker';
import mapboxgl, {LngLatLike, PaddingOptions} from 'mapbox-gl';
import {BREAKPOINTS} from '../constants/styles.constant.ts';

export const getLngLatLike = (lat: number, lng: number): LngLatLike => {
    return [lng, lat];
};

export const getMapFitBounds = (
    positions?: Position[],
): mapboxgl.LngLatBoundsLike | undefined => {
    if (!positions || positions.length === 0) {
        return undefined;
    }

    const bounds = new mapboxgl.LngLatBounds();

    positions.forEach((position) => {
        bounds.extend(getLngLatLike(position.latitude, position.longitude));
    });

    return bounds;
};

export const getDefaultMapFitBounds = (): PaddingOptions => {
    try {
        const documentWidth = document.documentElement.clientWidth;

        if (documentWidth < parseInt(BREAKPOINTS.TABLET)) {
            return {top: 200, bottom: 300, left: 50, right: 50};
        }
    } catch (e) {
        /* empty */
    }

    return {top: 100, bottom: 300, left: 50, right: 50};
};
