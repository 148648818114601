import React, {
    useCallback,
    useContext,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';
import mapboxgl from 'mapbox-gl';
import {MapChildren} from '../../types/map';
import {
    DEFAULT_MAP_FIT_BOUNDS_COORDS,
    DEFAULT_MAP_FIT_BOUNDS_OPTIONS,
    MAP_THEME,
} from '../../constants/global.constant.ts';

mapboxgl.accessToken = import.meta.env.VITE_MAPBOX_TOKEN;

interface ContextProps {
    map: mapboxgl.Map | null;
    isMapRendered: boolean;
    isStyleLoaded: boolean;
}

const Context = React.createContext<ContextProps>({
    map: null,
    isMapRendered: false,
    isStyleLoaded: false,
});

export const useMap = () => {
    return useContext(Context);
};

export interface MapRef {
    map: mapboxgl.Map | null;
}

interface MapProps {
    children?: MapChildren;
    fitBounds?: mapboxgl.LngLatBoundsLike;
    height?: string;
    width?: string;
    cooperativeGestures?: boolean;
    onDragStart?: () => void;
}

// eslint-disable-next-line react/display-name
export const Map = React.forwardRef<MapRef, MapProps>(
    (
        {
            children,
            fitBounds,
            width,
            height,
            cooperativeGestures = true,
            onDragStart,
        },
        ref,
    ) => {
        const mapRef = useRef<HTMLDivElement | null>(null);
        const canRenderMap = useRef(true);

        const [map, setMap] = useState<mapboxgl.Map | null>(null);
        const [isMapRendered, setIsMapRendered] = useState(false);
        const [isStyleLoaded, setIsStyleLoaded] = useState(false);

        useImperativeHandle(ref, () => ({
            map,
        }));

        const initializeMap = useCallback(() => {
            if (!mapRef.current || !canRenderMap.current) {
                return;
            }

            canRenderMap.current = false;

            const map = new mapboxgl.Map({
                container: mapRef.current,
                style: MAP_THEME,
                center: [0, 0],
                zoom: 1,
                cooperativeGestures,
                maxZoom: 13.5,
            });

            map.on('load', () => {
                map.setLayoutProperty('country-label', 'text-field', [
                    'get',
                    'name_fr',
                ]);

                map.resize();
            });

            map.on('render', () => {
                setMap(map);
                setIsMapRendered(true);
                setIsStyleLoaded(map.isStyleLoaded());
            });
        }, [cooperativeGestures]);

        useEffect(() => {
            const listener = () => onDragStart?.();

            if (map) {
                map.on('dragstart', listener);
            }

            return () => {
                if (map) {
                    map.off('dragstart', listener);
                }
            };
        }, [map, onDragStart]);

        const handleOnResize = useCallback(() => {
            if (map) {
                map.resize();
            }
        }, [map]);

        useEffect(() => {
            initializeMap();
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        useEffect(() => {
            try {
                if (map && fitBounds) {
                    map.fitBounds(fitBounds, DEFAULT_MAP_FIT_BOUNDS_OPTIONS);
                } else if (map) {
                    map.fitBounds(
                        DEFAULT_MAP_FIT_BOUNDS_COORDS,
                        DEFAULT_MAP_FIT_BOUNDS_OPTIONS,
                    );
                }
            } catch (e) {
                /* empty */
            }
        }, [map, fitBounds]);

        useEffect(() => {
            window.addEventListener('resize', handleOnResize);
            return () => window.removeEventListener('resize', handleOnResize);
        }, [handleOnResize]);

        useEffect(() => {
            const resizeObserver = new ResizeObserver(handleOnResize);

            if (mapRef.current) {
                resizeObserver.observe(mapRef.current);
            }

            return () => {
                if (mapRef.current) {
                    resizeObserver.unobserve(mapRef.current);
                }
            };
        }, [handleOnResize, mapRef]);

        return (
            <Context.Provider
                value={{
                    map,
                    isMapRendered,
                    isStyleLoaded,
                }}
            >
                <div
                    ref={mapRef}
                    style={{
                        height,
                        width,
                    }}
                />

                {children}
            </Context.Provider>
        );
    },
);
