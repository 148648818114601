import {DateTime, DateTimeFormatOptions} from 'luxon';
import {getBrowserLanguage} from './translations.utils.ts';
import {removeNonAlphanumericAtEnd} from './text.utils.ts';
import i18next from 'i18next';

export const getNow = (): string => {
    return DateTime.now().toISO() ?? new Date().toISOString();
};

export const formatDateToLocaleString = (
    isoDate: string,
    formatOptions: DateTimeFormatOptions,
): string => {
    return DateTime.fromISO(isoDate)
        .setLocale(getBrowserLanguage())
        .toLocaleString(formatOptions);
};

export const formatDateToLocaleStringWithoutYear = (
    isoDate: string,
    formatOptions: DateTimeFormatOptions,
): string => {
    const options = DateTime.fromISO(isoDate)
        .setLocale(i18next.language)
        .toLocaleParts(formatOptions);

    const filteredOptions: Intl.DateTimeFormatPart[] = [];

    for (let i = 0; i < options.length; i++) {
        const option = options[i];
        const nextOption = options[i + 1];

        if (
            (option.type === 'literal' && nextOption?.type !== 'year') ||
            (option.type !== 'literal' && option.type !== 'year')
        ) {
            filteredOptions.push(option);
        }
    }

    return filteredOptions
        .filter((option) => option.type !== 'year')
        .map((option) => option.value)
        .join('');
};

export const getDiffInMinutesBetweenISODates = (
    date1: string,
    date2: string,
): number => {
    return DateTime.fromISO(date1).diff(DateTime.fromISO(date2), ['minutes'])
        .minutes;
};

export const getDiffInDaysBetweenISODates = (
    date1: string,
    date2: string,
): number => {
    return DateTime.fromISO(date1).diff(DateTime.fromISO(date2), ['days']).days;
};

export const removeYearFromDate = (date: string) => {
    const dateWithoutYear = date.replace(/\d{4}/g, '').trim();
    return removeNonAlphanumericAtEnd(dateWithoutYear);
};
