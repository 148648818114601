import React from 'react';
import {InfoItem} from '../components/InfoItem';
import '../assets/stylesheets/georide-features-carousel.css';

export interface FeaturesListProps {}

export interface AnimatedFeaturesListProps {
    children?: React.ReactNode;
}

const AnimatedFeaturesList: React.FC<AnimatedFeaturesListProps> = ({
    children,
}) => {
    return (
        <div className={'features-list-container no-horizontal-padding'}>
            <div className="features-list">
                {Array.isArray(children) && children[children.length - 1]}
                {children}
                {children}
                {children}
            </div>
        </div>
    );
};

export const GeoRideFeaturesCarousel: React.FC<FeaturesListProps> = ({}) => {
    return (
        <AnimatedFeaturesList>
            <InfoItem label={'Localisation en temps réel'} icon={'pin'} />
            <InfoItem
                label={'Alertes instantanées'}
                icon={'notification-bell'}
            />
            <InfoItem label={'90% taux de récupération'} icon={'unlock-gyro'} />
            <InfoItem
                label={'Détection en sous-sol'}
                icon={'parking'}
                isPremium
            />
            <InfoItem label={'Trajets enregistrés'} icon={'trip'} />
            <InfoItem label={'Vidéos souvenirs'} icon={'movie-clap'} />
            <InfoItem
                label={'Sirène antivol'}
                icon={'ringing-bell'}
                isPremium
            />
            <InfoItem label={'Appel aux secours 24h/24'} icon={'ambulance'} />
            <InfoItem label={'Assistance après-vol 24h/24'} icon={'theft'} />
            <InfoItem label={'Statistiques de conduite'} icon={'graph'} />
            <InfoItem label={'Badge de proximité'} icon={'beacon'} isPremium />
            <InfoItem label={'Partage aux proches'} icon={'share'} />
            <InfoItem label={'Suivi de l’entretien'} icon={'motorcycle'} />
            <InfoItem label={'Application connectée'} icon={'app-connected'} />
        </AnimatedFeaturesList>
    );
};
