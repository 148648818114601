import mapboxgl, {LngLatBoundsLike} from 'mapbox-gl';
import {getDefaultMapFitBounds} from '../utils/map.utils.ts';

export const ROUTES = {
    GEORIDE_FEATURES_CAROUSEL: '/georide-features-carousel',
    MAP: '/map/:trackerId',
    PROGRESS: '/progress/:trackerId',
};

export const API_URL = import.meta.env.VITE_API_URL;

export const SOCKET_URL = import.meta.env.VITE_SOCKET_URL;

export const MAP_THEME = import.meta.env.VITE_MAPBOX_THEME;

export const DEFAULT_MAP_FIT_BOUNDS_OPTIONS: mapboxgl.FitBoundsOptions = {
    padding: getDefaultMapFitBounds(),
    duration: 700,
    maxZoom: 16.5,
};

export const DEFAULT_MAP_FIT_BOUNDS_COORDS: LngLatBoundsLike = [
    [-9.818263, 35.956767],
    [41.136138, 66.60112],
];

export const EARTH_RADIUS = 6371;

export const MAP_ANIMATION_DURATION = 700;
