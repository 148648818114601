import mapboxgl from 'mapbox-gl';
import {MarkerProperties, MarkerType} from '../../types/marker';
import {MARKERS_SPECS} from '../../utils/marker.utils.ts';
import {COLORS} from '../../constants/colors.constant.ts';
import {Callback} from '../../types';
import {MAP_ANIMATION_DURATION} from '../../constants/global.constant.ts';

export default class Marker extends mapboxgl.Marker {
    _animationFrame?: number;

    public static createDotMarker(
        type: MarkerType,
        onClick?: Callback,
    ): MarkerProperties {
        const el = document.createElement('div');

        const markerSpecs = MARKERS_SPECS[type];
        const defaultMarkerSpecs = MARKERS_SPECS.start;
        const markerSize = markerSpecs.size || defaultMarkerSpecs.size;

        el.style.width = `${markerSize}px`;
        el.style.height = `${markerSize}px`;
        el.style.backgroundColor =
            markerSpecs.color || defaultMarkerSpecs.color;
        el.style.borderWidth = `${markerSpecs.borderWidth || defaultMarkerSpecs.borderWidth}px`;
        el.style.borderStyle = 'solid';
        el.style.borderColor = COLORS.OFF_WHITE;
        el.style.borderRadius = '50%';
        el.style.boxShadow = `0px 0px 10px 1px ${COLORS.BLACK + '33'}`;

        if (onClick) {
            el.style.cursor = 'pointer';
            el.onclick = onClick;
        }

        return el;
    }

    animateTo(
        latitude: number,
        longitude: number,
        animationDuration = MAP_ANIMATION_DURATION,
    ) {
        if (this._animationFrame) {
            window.cancelAnimationFrame(this._animationFrame);
        }

        const actualPositions = this.getLngLat();

        const diffLng = longitude - actualPositions.lng;
        const diffLat = latitude - actualPositions.lat;

        const fps = 30;
        let startTime = 0;
        let then = 0;
        const interval = 1000 / fps;

        const animate = (now: number) => {
            if (!then) then = now;

            if (!startTime) startTime = now;

            //Limit fps for better performances
            const delta = now - then;
            if (delta < interval)
                return (this._animationFrame =
                    window.requestAnimationFrame(animate));

            then = now - (delta % interval);

            const timeRemaining = startTime + animationDuration - now; //ex: 250ms/300ms
            const completion = now - startTime; //ex: 50ms/300ms
            const percentageCompletion = (completion / animationDuration) * 100; //ex: 16.5% of 300ms

            if (Math.floor(timeRemaining) <= 0) {
                this.setLngLat([longitude, latitude]);

                if (this._animationFrame) {
                    window.cancelAnimationFrame(this._animationFrame);
                }

                return;
            }

            const nextPositions = {
                lng:
                    actualPositions.lng +
                    (diffLng * percentageCompletion) / 100,
                lat:
                    actualPositions.lat +
                    (diffLat * percentageCompletion) / 100,
            };

            this.setLngLat(nextPositions);

            this._animationFrame = window.requestAnimationFrame(animate);
        };

        this._animationFrame = window.requestAnimationFrame(animate);
    }
}
