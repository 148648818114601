export const COLORS = {
    WHITE: '#FFFFFF',
    BLACK: '#000000',
    ROSSO_DUCATI: '#CC0000',
    OFF_WHITE: '#FAFAFA',
    SMOKY_BLACK: '#0F0F0F',
    LIGHT_GREY: '#EBEBEB',
    FEATURE_GREY: '#BBBCBC',
    DARK_GREY: '#666666',
    GREEN: '#0aa000',
    ORANGE: '#FA742B',
};
