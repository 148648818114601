import React from 'react';
import styled from '@emotion/styled';
import {COLORS} from '../../../constants/colors.constant.ts';
import {
    extractNumberFromFormattedDistance,
    extractUnitFromFormattedDistance,
} from '../../../utils/utils.utils.ts';
import {BREAKPOINTS} from '../../../constants/styles.constant.ts';

export interface InfoItemsProps {
    label: string;
    showLabelStatus?: boolean;
    value: string;
    isLight?: boolean;
    isOdometer?: boolean;
}

export const InfoItems: React.FC<InfoItemsProps> = ({
    label,
    showLabelStatus,
    value,
    isLight,
    isOdometer,
}) => {
    return (
        <Container>
            <LabelContainer isOdometer={isOdometer}>
                <Label isLight={isLight}>{label}</Label>

                {showLabelStatus && <RunningStatus />}
            </LabelContainer>

            {isOdometer ? (
                <OdometerValue>
                    {extractNumberFromFormattedDistance(value)}

                    <span>{extractUnitFromFormattedDistance(value)}</span>
                </OdometerValue>
            ) : (
                <Value isLight={isLight}>{value}</Value>
            )}
        </Container>
    );
};

const Container = styled.div``;

const LabelContainer = styled.div<{isOdometer: InfoItemsProps['isOdometer']}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: ${({isOdometer}) => (isOdometer ? '-1px' : '4px')};
`;

const Label = styled.p<Pick<InfoItemsProps, 'isLight'>>`
    font-family: Ducati-Style-Condensed-Bold, sans-serif;
    font-size: 1.35em;
    line-height: 1;
    color: ${({isLight}) => (isLight ? COLORS.DARK_GREY : COLORS.BLACK)};

    @media screen and (max-width: ${BREAKPOINTS.TABLET}) {
        font-size: 1.3em;
    }

    @media screen and (max-width: ${BREAKPOINTS.TABLET}) {
        font-size: 0.9em;
    }
`;

const RunningStatus = styled.div`
    width: 1em;
    height: 1em;
    border-radius: 50%;
    background-color: ${COLORS.GREEN};
    margin-left: 12px;

    @media screen and (max-width: ${BREAKPOINTS.MOBILE}) {
        font-size: 0.8em;
    }
`;

const Value = styled.p<Pick<InfoItemsProps, 'isLight'>>`
    font-family: Ducati-Style-Bold, sans-serif;
    font-size: 2em;
    line-height: 1;
    color: ${({isLight}) => (isLight ? COLORS.DARK_GREY : COLORS.BLACK)};

    @media screen and (max-width: ${BREAKPOINTS.TABLET}) {
        font-size: 1.9em;
    }

    @media screen and (max-width: ${BREAKPOINTS.MOBILE}) {
        font-size: 1.2em;
    }
`;

const OdometerValue = styled.p<Pick<InfoItemsProps, 'isLight'>>`
    font-family: Ducati-Style-Bold, sans-serif;
    font-size: 3em;
    line-height: 1;
    color: ${({isLight}) => (isLight ? COLORS.DARK_GREY : COLORS.BLACK)};

    @media screen and (max-width: ${BREAKPOINTS.TABLET}) {
        font-size: 2.8em;
    }

    @media screen and (max-width: ${BREAKPOINTS.MOBILE}) {
        font-size: 2.1em;
    }

    & > span {
        font-family: Ducati-Style-Bold, sans-serif;
        font-size: 0.6em;
        line-height: 0.5em;
        margin-left: 5px;
    }
`;
