import {MarkerSpecs, MarkerType} from '../types/marker';
import {COLORS} from '../constants/colors.constant.ts';

export const MARKERS_SPECS: Record<MarkerType, MarkerSpecs> = {
    start: {
        color: COLORS.DARK_GREY,
        size: 18,
        borderWidth: 3,
    },
    current: {
        color: COLORS.ROSSO_DUCATI,
        size: 32,
        borderWidth: 4,
    },
};
