import i18next, {Resource} from 'i18next';
import {initReactI18next} from 'react-i18next';
import {FALLBACK_LANGUAGE} from '../constants/translations.constant.ts';
import fr from '../locales/fr/translations.json';
import en from '../locales/en/translations.json';
import de from '../locales/de/translations.json';
import es from '../locales/es/translations.json';
import it from '../locales/it/translations.json';
import nl from '../locales/nl/translations.json';
import pl from '../locales/pl/translations.json';
import pt from '../locales/pt/translations.json';

const resources: Resource = {
    fr: {
        translation: fr,
    },
    en: {
        translation: en,
    },
    de: {
        translation: de,
    },
    es: {
        translation: es,
    },
    it: {
        translation: it,
    },
    nl: {
        translation: nl,
    },
    pl: {
        translation: pl,
    },
    pt: {
        translation: pt,
    },
};

export const getBrowserLanguage = () => {
    if (typeof window === 'undefined') {
        return FALLBACK_LANGUAGE;
    }

    const browserLanguage = window.navigator.language;

    if (!browserLanguage) {
        return FALLBACK_LANGUAGE;
    }

    const [language] = browserLanguage.split('-');

    // If the language is not supported, fallback to the default language
    if (!resources[language]) {
        return FALLBACK_LANGUAGE;
    }

    return language;
};

export const initTranslations = () => {
    i18next.use(initReactI18next).init({
        resources: resources,
        lng: getBrowserLanguage(),
        fallbackLng: FALLBACK_LANGUAGE,
        interpolation: {
            escapeValue: false,
        },
    });
};

export const formatNumber = (number: number) => {
    return new Intl.NumberFormat(getBrowserLanguage()).format(number);
};

export const formatDistance = (number: number) => {
    return new Intl.NumberFormat(getBrowserLanguage(), {
        style: 'unit',
        unit: 'kilometer',
    }).format(number);
};
