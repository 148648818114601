import React from 'react';
import styled from '@emotion/styled';
import {useTranslation} from 'react-i18next';
import {BREAKPOINTS} from '../constants/styles.constant.ts';
import {LINKS} from '../constants/links.constant.ts';

export interface PoweredByProps {
    logoSrc: string;
    bold?: boolean;
}

export const PoweredBy: React.FC<PoweredByProps> = ({logoSrc, bold}) => {
    const {t} = useTranslation();

    return (
        <PoweredByContainer href={LINKS.POWERED_BY} target={'_blank'}>
            <PoweredByText bold={bold}>
                {t('components.PoweredBy.poweredBy')}
            </PoweredByText>

            <PoweredByLogo alt={'GeoRide logo'} src={logoSrc} />
        </PoweredByContainer>
    );
};

const PoweredByContainer = styled.a`
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    color: inherit;

    &:hover {
        cursor: pointer;
    }

    &:visited {
        color: inherit;
    }

    @media screen and (max-width: ${BREAKPOINTS.MOBILE}) {
        margin-top: 2px;
    }
`;

const PoweredByText = styled.p<Pick<PoweredByProps, 'bold'>>`
    font-family: ${({bold}) =>
        bold
            ? 'Ducati-Style-Bold, sans-serif'
            : 'Ducati-Style-Regular, sans-serif'};
    font-size: 1em;

    @media screen and (max-width: ${BREAKPOINTS.TABLET}) {
        font-size: 0.9em;
    }

    @media screen and (max-width: ${BREAKPOINTS.MOBILE}) {
        font-size: 0.7em;
    }
`;

const PoweredByLogo = styled.img`
    height: 1.25em;
    margin-left: 5px;
    margin-top: 2.5px;

    @media screen and (max-width: ${BREAKPOINTS.TABLET}) {
        font-size: 0.9em;
        margin-top: 0px;
    }

    @media screen and (max-width: ${BREAKPOINTS.MOBILE}) {
        font-size: 0.7em;
    }
`;
