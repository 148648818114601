import React from 'react';
import ReactDOM from 'react-dom/client';
import Router from './router/Router.tsx';
import {BrowserRouter} from 'react-router-dom';
import {initTranslations} from './utils/translations.utils.ts';
import './assets/stylesheets/fonts.css';
import './assets/stylesheets/index.css';
import 'sanitize.css';
import 'mapbox-gl/dist/mapbox-gl.css';

initTranslations();

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <BrowserRouter>
            <Router />
        </BrowserRouter>
    </React.StrictMode>,
);
