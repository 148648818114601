import React from 'react';
import styled from '@emotion/styled';
import pin from '../assets/icons/pin.svg';
import notificationBell from '../assets/icons/notification-bell.svg';
import ambulance from '../assets/icons/ambulance.svg';
import appConnected from '../assets/icons/app-connected.svg';
import beacon from '../assets/icons/beacon.svg';
import graph from '../assets/icons/graph.svg';
import parking from '../assets/icons/parking.svg';
import ringingBell from '../assets/icons/ringing-bell.svg';
import share from '../assets/icons/share.svg';
import theft from '../assets/icons/theft.svg';
import trip from '../assets/icons/trip.svg';
import motorcycle from '../assets/icons/motorcycle.svg';
import unlockGyro from '../assets/icons/unlock-gyro.svg';
import movieClap from '../assets/icons/movie-clap.svg';
import gradientTick from '../assets/icons/gradient-tick.svg';
import ratingStart from '../assets/icons/rating-start.svg';
import lock from '../assets/icons/lock.svg';
import video from '../assets/icons/video.svg';
import play from '../assets/icons/play.svg';

const icons = {
    pin,
    'notification-bell': notificationBell,
    ambulance,
    'app-connected': appConnected,
    beacon,
    graph,
    parking,
    'ringing-bell': ringingBell,
    share,
    theft,
    trip,
    'unlock-gyro': unlockGyro,
    motorcycle,
    'movie-clap': movieClap,
    lock,
    'gradient-tick': gradientTick,
    'rating-start': ratingStart,
    video,
    play,
};

export type IconName = keyof typeof icons;

export interface IconProps {
    name: IconName;
    size?: number;
}

export const Icon: React.FC<IconProps> = ({name, size}) => {
    return <Img src={icons[name]} alt={`${name}-icon`} size={size ?? 26} />;
};

const Img = styled.img<Pick<IconProps, 'size'>>`
    width: ${({size}) => size}px;
    height: ${({size}) => size}px;
`;
