import {EARTH_RADIUS} from '../constants/global.constant.ts';

function degToRad(degrees: number) {
    return (degrees * Math.PI) / 180;
}

export default function calcDistance(
    lat1: number,
    lon1: number,
    lat2: number,
    lon2: number,
) {
    if (
        lat1 === undefined ||
        lat2 === undefined ||
        lon1 === undefined ||
        lon2 === undefined
    ) {
        return 0;
    }

    const dLat = degToRad(lat2 - lat1);
    const dLon = degToRad(lon2 - lon1);
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.sin(dLon / 2) *
            Math.sin(dLon / 2) *
            Math.cos(degToRad(lat1)) *
            Math.cos(degToRad(lat2));
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = EARTH_RADIUS * c;

    return Math.ceil(d * 1000);
}
