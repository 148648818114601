export const extractNumberFromFormattedDistance = (
    formattedDistance: string,
) => {
    return parseInt(formattedDistance.match(/\d+/g)!.join(''));
};

export const extractUnitFromFormattedDistance = (formattedDistance: string) => {
    return formattedDistance.match(/[a-zA-Z]+/g)!.join('');
};

export const getRandomNumber = () => {
    return Math.round(Math.random() * 1000000);
};

export const getRandomKey = (prefix?: string) => {
    const baseKey = `${Date.now()}-${getRandomNumber()}`;

    if (prefix) {
        return `${prefix}-${baseKey}`;
    }

    return baseKey;
};
