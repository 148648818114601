import React from 'react';
import {Route, Routes} from 'react-router-dom';
import {ROUTES} from '../constants/global.constant.ts';
import {MapView} from '../features/map/views/MapView.tsx';
import {ProgressView} from '../features/progress/views/ProgressView.tsx';
import {NotFoundView} from '../features/not-found/views/NotFoundView.tsx';
import {GeoRideFeaturesCarousel} from '../features/georide-features-carousel/views/GeoRideFeaturesCarousel.tsx';

export interface AppProps {}

const Router: React.FC<AppProps> = ({}) => {
    return (
        <Routes>
            <Route
                path={ROUTES.GEORIDE_FEATURES_CAROUSEL}
                element={<GeoRideFeaturesCarousel />}
            />

            <Route path={ROUTES.MAP} element={<MapView />} />

            <Route path={ROUTES.PROGRESS} element={<ProgressView />} />

            <Route path={'*'} element={<NotFoundView />} />
        </Routes>
    );
};

export default Router;
